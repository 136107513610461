body {
  background-color: rgb(18, 18, 18);
}

.root {
  display: flex;
  height: 100%;
  width: 100%;
}

a {
  color: lightblue;
}